import {useEffect, useState} from "react";
import {ClientEvent} from "clientevent";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";

export type DialogContentType = DialogControlConfirm | DialogControlPrompt;

export interface DialogControlConfirm {
  title: string;
  type?: "confirm"
  message: string | JSX.Element;
  buttons: string[];
  onClick: (value: string | null) => void;
}

export interface DialogControlPrompt {
  title: string;
  type: "prompt"
  helperText?: JSX.Element | string;
  regex?: RegExp;
  regexStopValues?: string[];
  defaultValue?: string | null;
  buttons: string[];
  onClick: (btnValue: string | null, inputValue: string) => void;
}

interface Props {
}

export function DialogControl(props: Props) {

  const [dialog, setDialog] = useState<DialogContentType | null>(null);
  const [textfieldValue, setTextfieldValue] = useState<string>('');

  /*
  const confirmDialog = {
    title: "Folder Delete",
    type: "confirm",
    message: "Are you sure you want to delete this folder?",
    buttons: ["Yes", "No"],
    onClick: (value: string)=>{
      if (value === "Yes") deleteFolder(id);
    }
  } as DialogContent

   */
  useEffect(() => {
    const event = ClientEvent.subscribe("DIALOG", handleContent);
    return () => {
      event.unsubscribe();
    }
  }, []);

  const handleContent = (content: DialogContentType) => {
    if (content.type === 'prompt' && content.hasOwnProperty('defaultValue') && content["defaultValue"] != null) {
      setTextfieldValue(content["defaultValue"]);
    }
    setDialog(content);
  }

  const passValue = (value: string | null) => {
    if (dialog?.type === 'prompt') {
      if (dialog.regexStopValues && dialog.regexStopValues.indexOf(value) > -1 && dialog.regex && !dialog.regex.test(textfieldValue)) return;
      // @ts-ignore
      dialog?.onClick && dialog.onClick(value, textfieldValue);
    } else {
      // @ts-ignore
      dialog?.onClick && dialog.onClick(value);
    }
    setTextfieldValue('');
    setDialog(null);
  }

  if (!dialog) return null;
  return <Dialog aria-labelledby="dialogcontrol-title"
                 aria-describedby="dialogcontrol-description"
                 open={true}
                 fullWidth={true}
                 onClose={() => passValue(null)}
                 title={dialog.title}>
    <DialogTitle id="dialogcontrol-title">
      {dialog.title}
    </DialogTitle>
    <DialogContent>
      {(dialog.type === 'confirm' || dialog.type === undefined) && (
        <DialogContentText id="dialogcontrol-description">
          {dialog.type === 'confirm' && dialog.message || ""}
        </DialogContentText>
      )}
      {dialog.type === 'prompt' && (
        <>
          <TextField
            data-testid="text-field"
            size="small"
            variant="outlined"
            style={{width: '100%'}}
            error={dialog.regex && !dialog.regex.test(textfieldValue) ? true : false}
            value={textfieldValue}
            helperText={dialog.regex && !dialog.regex.test(textfieldValue) ? "Not Valid Input" : undefined}
            onChange={(e) => setTextfieldValue(e.target.value)}
          />
          {dialog.helperText || null}
        </>
      )}
    </DialogContent>
    <DialogActions>
      {dialog.buttons.map((button, index) => {
        return <Button key={index} onClick={() => passValue(button)}>{button}</Button>
      })}
    </DialogActions>
  </Dialog>
}