import {useEffect, useState} from "react";

// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import {getData} from "../../api/api";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {PackageCards} from "../../components/PackageCards/PackageCards";
import {CreditOrder} from "../../interfaces/credits";
import {UserType} from "../../interfaces/users";
import {TSuiButton} from "../../components/TSuiButton";
import EditIcon from "@mui/icons-material/Edit";
import Payment from "@mui/icons-material/Payment";
import LockReset from "@mui/icons-material/LockReset";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "../../components/DataTable";
import dayjs from "dayjs";
import {epochMsToDateString} from "../../shared/dateutils";
import {formatCurrency} from "../../shared/util";

interface Props extends RouteComponentProps {

}

interface CreditsPackageType {
  credits: CreditOrder[];
  packages: any[];
}

export function Credits(props: Props) {
  const [data, setData] = useState<CreditsPackageType>({
    credits: [], packages: []
  });
  const [editReservation, setEditReservation] = useState<string | undefined>();
  const [newReservation, setNewReservation] = useState<{
    start: number,
    end: number,
    office_id?: string,
    user_id: string
  } | undefined>();
  const {state: {configData, balance, userInfo}} = useGlobalState();
  const navigate = useNavigate();

  const refreshList = async () => {
    const data = await getData("credits").then((response) => (response.data || {
      credits: [], packages: []
    })).catch(() => ({
      credits: [], packages: []
    }));
    setData(data);
  }

  useEffect(() => {
    refreshList();
  }, [])


  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            Available Credit Packages
          </TSuiTypography>
        </TSuiBox>
        <PackageCards packageData={data.packages || []} userId={configData.id}/>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            My Purchase History
          </TSuiTypography>
          <DataTable<CreditOrder>
            isSorted={false}
            canSearch={true}
            table={{
              columns: [{
                Header: "Date",
                accessor: "activate",
                width: "20%",
                Cell: ({cell: {value}}) => (<>{value ? epochMsToDateString(value * 1000) : ""}</>)
              }, {
                Header: "Credits",
                accessor: "credits",
                width: "5%",
              }, {
                Header: "Amount",
                accessor: "amount",
                width: "5%",
                Cell: ({cell: {value}}) => (<>{formatCurrency(value)}</>)
              }, {
                Header: "Details",
                accessor: "details",
                width: "50%",
                // Cell: ({cell: {value}}) => (<>{value ? "Yes" : "No"}</>)
              },
                // {
                //   Header: "Actions",
                //   accessor: "id",
                //   width: "20%",
                //   align: "right",
                //   disableSortBy: true,
                //   Cell: ({cell: {value}}) => (
                //     <>
                //       {/*<TSuiButton iconOnly={true}*/}
                //       {/*            variant="gradient"*/}
                //       {/*            color="info"*/}
                //       {/*            title={"Edit User"}*/}
                //       {/*            onClick={() => navigate(`/settings/users/${value}`)}>*/}
                //       {/*  <EditIcon/>*/}
                //       {/*</TSuiButton>*/}
                //       {/*<TSuiButton iconOnly={true}*/}
                //       {/*            variant="gradient"*/}
                //       {/*            color="warning"*/}
                //       {/*            title={"Credits"}*/}
                //       {/*            onClick={() => navigate(`/settings/users/${value}/credits`)}>*/}
                //       {/*  <Payment/>*/}
                //       {/*</TSuiButton>*/}
                //       {/*<TSuiButton iconOnly={true}*/}
                //       {/*            variant="gradient"*/}
                //       {/*            color="dark"*/}
                //       {/*            title={"Reset Password"}*/}
                //       {/*            onClick={() => resetPassword(value)}>*/}
                //       {/*  <LockReset/>*/}
                //       {/*</TSuiButton>*/}
                //       {/*<TSuiButton iconOnly={true}*/}
                //       {/*            variant="gradient"*/}
                //       {/*            color="error"*/}
                //       {/*            title={"Remove"}*/}
                //       {/*            onClick={() => removeConfirm(value)}>*/}
                //       {/*  <DeleteIcon/>*/}
                //       {/*</TSuiButton>*/}
                //     </>
                //   )
                // }
              ],
              rows: data.credits || []
            }}/>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
