import React, { createContext, useContext, useState } from "react";

export interface TabPanelContextInterface {
  tab: string | false;
}

interface ITabPanelContext {
  state: Partial<TabPanelContextInterface>;
  setState: (partialState: Partial<TabPanelContextInterface>) => void;
  clearState: () => void;
}

const TabPanelContext = createContext({} as ITabPanelContext);

interface TabPanelContextProviderProps {
  value: Partial<TabPanelContextInterface>;
  children: React.ReactNode;
}

const TabPanelContextProvider = (props: TabPanelContextProviderProps) => {
  const [state, setTabState] = useState<TabPanelContextInterface>({
    tab: props.value.tab || false,
  });
  const clearState = () => setTabState({ tab: false });
  const setState = (value: Partial<TabPanelContextInterface>) => {
    setTabState({ ...state, ...value });
  };
  return (
    <TabPanelContext.Provider value={{ state, setState, clearState }}>
      {props.children}
    </TabPanelContext.Provider>
  );
};
const useTabContext = () => {
  const context = useContext(TabPanelContext);
  if (!context) {
    throw new Error("useTabContext must be used within a GlobalStateContext");
  }
  return context;
};

export { TabPanelContextProvider, useTabContext };
