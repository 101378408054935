import React, {createContext, useContext, useEffect, useState} from "react";
import {webDataStore} from "../store/webDataStore";
import {parseCookie} from "../shared/util";

export interface UserInfo {
  token: string;
  username: string;
}

export interface GlobalStateInterface {
  userInfo: null | UserInfo;
  attemptedTokenRead: boolean;
  configData: any;
  balance: number;
}

interface IGlobalStateContext {
  state: Partial<GlobalStateInterface>
  setState: (partialState: Partial<GlobalStateInterface>) => void;
}

const GlobalStateContext = createContext({} as IGlobalStateContext)


const GlobalStateProvider = ({
                               children,
                               value = {attemptedTokenRead: false, userInfo: null} as GlobalStateInterface,
                             }: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}) => {
  const [state, setStateOriginal] = useState(value);
  const setState = (value: Partial<GlobalStateInterface>) => {
    if (value.userInfo?.token) {
      // if token is being set, set to global store for API
      webDataStore.setDataStore({token: value.userInfo.token});
    }
    if (value.userInfo?.token === null) {
      // @ts-ignore
      webDataStore.data.token = undefined;
      // @ts-ignore
      delete webDataStore.data.token;
    }
    setStateOriginal((prev) => {
      return {...prev, ...value}
    });
  }

  useEffect(() => {
    const cookies = parseCookie(document.cookie);
    if (cookies.token) {
      setState({userInfo: {token: cookies.token, username: ""}, attemptedTokenRead: true})
    } else {
      setState({attemptedTokenRead: true});
    }
  }, []);

  return (
    <GlobalStateContext.Provider value={{state, setState}}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export {GlobalStateProvider, useGlobalState};
