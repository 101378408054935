import {epochNow, epochToMs, parseDate} from "../shared/dateutils";

export interface EventType {
  id: string;
  title: string;
  status: string;
  resourceId: string;
  user_id: string;
  start: string;
  end: string;
  backgroundColor?: string;
}

export interface EventTypeDTO {
  id: string;
  status: string;
  resource: string;
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  start: number;
  end: number;
}

function colorForStatus(event: EventTypeDTO): string {
  if (event.status === "checked_in") {
    if (epochToMs(event.end) < epochNow().unix() * 1000) {
      return "orange"
    } else {
      return "green"
    }
  }
  if (event.status === "checked_out") {
    return "red"
  }
  return "default"
}

export function fromEventTypeDTO(eventDto: EventTypeDTO): EventType {
  const {resource, user_first_name, user_last_name, start, end, ...rest} = eventDto;
  return {
    ...rest,
    title: `${[user_first_name, user_last_name].join(" ")}`,
    resourceId: resource,
    start: parseDate(epochToMs(start)).toISOString(),
    end: parseDate(epochToMs(end)).toISOString(),
    backgroundColor: colorForStatus(eventDto)
  }
}