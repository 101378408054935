
// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import {TSuiButton} from "../../../components/TSuiButton";
import {TSuiBox} from "../../../components/TSuiBox";
import {TSuiTypography} from "../../../components/TSuiTypography";

// Custom styles for the SidenavCard
import { card, cardContent, cardIconBox, cardIcon } from "./styles/sidenavCard";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "../../../context";

function SidenavCard() {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
        <TSuiBox
          bgColor="white"
          width="2rem"
          height="2rem"
          borderRadius="md"
          shadow="md"
          mb={2}
          sx={cardIconBox}
        >
          <Icon fontSize="medium" sx={(theme) => cardIcon(theme, { sidenavColor })}>
            star
          </Icon>
        </TSuiBox>
        <TSuiBox lineHeight={1}>
          <TSuiTypography variant="h6" color="white">
            Need help?
          </TSuiTypography>
          <TSuiBox mb={1.825} mt={-1}>
            <TSuiTypography variant="caption" color="white" fontWeight="medium">
              Please check our docs
            </TSuiTypography>
          </TSuiBox>
          <TSuiButton
            component={Link}
            href="https://www.creative-tim.com/learning-lab/react/quick-start/soft-ui-dashboard/"
            target="_blank"
            rel="noreferrer"
            size="small"
            color="white"
            fullWidth
          >
            documentation
          </TSuiButton>
        </TSuiBox>
      </CardContent>
    </Card>
  );
}

export default SidenavCard;
