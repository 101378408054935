import axios from 'axios';
import {webDataStore} from "../store/webDataStore";

// const API_BASE = "https://quietwork.hostedapp.cloud/";
// const API_BASE = "http://192.168.234.170:8080/";
// const API_BASE = "http://0.0.0.0:8080/";
const API_BASE = "/";

interface IHeaders {
  [key: string]: string;
}

const API_URL = API_BASE + "api/";

let tHeaders: IHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};


export function postData<T>(url = '', data: Partial<T> = {}) {
  // if (token.length > 0) tHeaders['token'] = token;
  // tHeaders['device-id'] = deviceDataStore.data.login || ""
  // tHeaders['pin'] = deviceDataStore.data.password || ""
  tHeaders['token'] = webDataStore.data.token || ""
  return axios({
    baseURL: API_URL,
    url: url,
    method: 'post',
    headers: tHeaders,
    responseType: 'json',
    data: data,
  });
}

export function putData<T>(url = '', data: Partial<T> = {}) {
  // if (token.length > 0) tHeaders['token'] = token;
  // tHeaders['device-id'] = deviceDataStore.data.login || ""
  // tHeaders['pin'] = deviceDataStore.data.password || ""
  tHeaders['token'] = webDataStore.data.token || ""
  return axios({
    baseURL: API_URL,
    url: url,
    method: 'put',
    headers: tHeaders,
    responseType: 'json',
    data: data,
  });
}

export function getData<T>(url = "", data: Partial<T> = {}) {
  // if (token.length > 0) tHeaders['token'] = token;
  // tHeaders['device-id'] = deviceDataStore.data.login || ""
  // tHeaders['pin'] = deviceDataStore.data.password || ""
  tHeaders['token'] = webDataStore.data.token || ""
  return axios({
    baseURL: API_URL,
    params: data,
    url: url,
    method: 'get',
    headers: tHeaders,
    responseType: 'json',
  });
}

export function deleteData<T>(url = '', data: Partial<T> = {}) {
  // if (token.length > 0) tHeaders['token'] = token;
  // tHeaders['device-id'] = deviceDataStore.data.login || ""
  // tHeaders['pin'] = deviceDataStore.data.password || ""
  tHeaders['token'] = webDataStore.data.token || ""
  return axios({
    baseURL: API_URL,
    url: url,
    method: 'delete',
    headers: tHeaders,
    responseType: 'json',
    data: data,
  });
}

// export function uploadFile(url = "", data, token, username, password) {
//   var lHeaders = {
//     'Accept': 'application/json',
//     'Content-Type': 'multipart/form-data',
//   };
//   if (token.length > 0) lHeaders['token'] = token;
//   if (username.length > 0) lHeaders['username'] = username;
//   if (password.length > 0) lHeaders['password'] = password;
//   return module.exports.fetchWithTimeout(API_URL + url, {
//     method: 'POST',
//     cache: false,
//     headers: lHeaders,
//     body: data,
//     timeout: 45000
//   }).then(async response => {
//     return await response.json();
//   });
//   // var lHeaders = {
//   //   'Content-Type': 'multipart/form-data',
//   // };
//   // if (token.length > 0) lHeaders['token'] = token;
//   // if (username.length > 0) lHeaders['username'] = username;
//   // if (password.length > 0) lHeaders['password'] = password;
//   // return axios({
//   //   baseURL: API_URL,
//   //   url: url,
//   //   method: "POST",
//   //   cache: false,
//   //   headers: lHeaders,
//   //   responseType: 'json',
//   //   data: data,
//   // }).then(response => response.data);
// }

// export function fetchWithTimeout(resource, options) {
//   const {timeout = 30000} = options;
//
//   const controller = new AbortController();
//   const id = setTimeout(() => controller.abort(), timeout);
//
//   return fetch(resource, {
//     ...options,
//     signal: controller.signal
//   }).finally(() => {
//     clearTimeout(id);
//   });
// }

