import {TypographyProps, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props extends Omit<TypographyProps, "fontWeight"> {
  component?: any;
  children: string | JSX.Element | JSX.Element[];
  color?: "inherit" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "text" | "white";
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
  verticalAlign?: "unset" | "baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle" | "top" | "bottom";
  fontWeight?: false | "light" | "regular" | "medium" | "bold";
  opacity?: number;
  textGradient?: boolean;

  [key: string]: any;
}

export function TSuiTypography(props: Props) {
  const {children, color, textTransform, verticalAlign, fontWeight, opacity, textGradient, ...rest} = props;
  // @ts-ignore
  const {palette, typography, functions} = useTheme();

  // @ts-ignore
  const {gradients, transparent} = palette;
  const {fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold} = typography;
  const {linearGradient} = functions;

  // fontWeight styles
  const fontWeights = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color !== "inherit" && color !== "text" && color !== "white" && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.dark.main, gradients.dark.state),
    display: "inline-block",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: transparent.main,
    position: "relative",
    zIndex: 1,
  });

  // @ts-ignore
  return <Typography style={{
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: "none",
    color: color === "inherit" || !palette[color] ? "inherit" : palette[color].main,
    fontWeight: fontWeight && fontWeights[fontWeight] || undefined,
    ...(textGradient && gradientStyles()),
  }} {...rest}>{children}</Typography>;
}