import * as React from 'react';
import {SyntheticEvent, useEffect, useState} from 'react';
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Close from "@mui/icons-material/Close";
import {ClientEvent} from "clientevent";

interface Props {
  message?: string;
  onClose?: () => void;
}

export function SnackbarPopup(props: Props) {
  const {message, onClose} = props;
  const [open, setOpen] = useState(props.message && props.message !== '' ? true : false);
  const [messageText, setMessageText] = useState("");

  useEffect(() => {
    setOpen(!!(message && message !== ''));
    setMessageText(message || "");
  }, [message]);

  useEffect(() => {
    if (!message && !onClose) {
      const subscription = ClientEvent.subscribe("SNACKBAR", displayMessage);
      return () => {
        subscription.unsubscribe();
      }
    }
  }, []);

  const displayMessage = (payload: string) => {
    setMessageText(payload);
    setOpen(true);
  }

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
    }
  };

  return (
    <Snackbar
      style={{paddingBottom: "120px"}}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        message={
          <span data-testid={'snackbar-popup-test'}>
            {messageText}
          </span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <Close/>
          </IconButton>,
        ]}
      />
    </Snackbar>

  );

}
