import {Dashboard} from "../pages/Dashboard/Dashboard";
import {Credits} from "../pages/Credits/Credits";
import {CreditPurchaseWaiting} from "../pages/Credits/CreditPurchaseWaiting";
import {Packages} from "../pages/Packages/Pacakges";
import {PackageAddEdit} from "../pages/Packages/PackageAddEdit";
import {Users} from "../pages/Users/Users";
import {UserAddEdit} from "../pages/Users/UserAddEdit";
import {UserCredits} from "../pages/Users/UserCredits";
import {AllReservations} from "../pages/Reservations/AllReservations";
import {ReservationsCalendar} from "../pages/Reservations/ReservationsCalendar";
import {Offices} from "../pages/Offices/Offices";
import {OfficeAddEdit} from "../pages/Offices/OfficeAddEdit";
import {Rooms} from "../pages/Offices/Rooms";
import {RoomAddEdit} from "../pages/Offices/RoomAddEdit";
import {Logout} from "../pages/Logout/Logout";
import {APDisplay} from "../pages/APDisplay/APDisplay";
import {Redirect, Router} from "@reach/router";
import {Error404} from "../pages/Error/404";
import React from "react";

export function UserRoutes() {
  return <Router>
    <Dashboard path={"/"}/>
    <Credits path={"/credits"}/>
    <CreditPurchaseWaiting path={"/credits/purchase/:reference_id"}/>
    <Packages path={"/settings/packages"}/>
    <PackageAddEdit path={"/settings/packages/add"}/>
    <PackageAddEdit path={"/settings/packages/:package_id"}/>
    <Users path={"/settings/users"}/>
    <UserAddEdit path={"/settings/users/:user_id"}/>
    <UserCredits path={"/settings/users/:user_id/credits"}/>
    <AllReservations path={"/reservations-all"}/>
    <ReservationsCalendar path={"/reservations-calendar"}/>
    <Offices path={"/settings/office"}/>
    <OfficeAddEdit path={"/settings/office/add"}/>
    <OfficeAddEdit path={"/settings/office/:office_id"}/>
    <Rooms path={"/settings/office/:office_id/spaces"}/>
    <RoomAddEdit path={"/settings/office/:office_id/spaces/add"}/>
    <RoomAddEdit path={"/settings/office/:office_id/spaces/:room_id"}/>
    <Logout path={"/logout"}/>
    <APDisplay path={"/display/office/:office_id"}/>
    <Redirect noThrow={true} from={"/login"} to={"/"}/>
    <Error404 path={"*"}/>
  </Router>;
}