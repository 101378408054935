import {useEffect} from "react";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../../../components/TSuiBox";

// Soft UI Dashboard PRO React context
import {useSoftUIController, setLayout} from "../../../context";
import {useLocation} from "@reach/router";

interface Props {
  background?: "white" | "light" | "default" | string;
  children: JSX.Element | JSX.Element[];

}

export function PageLayout({background, children}: Props) {
  const [, dispatch] = useSoftUIController();
  const {pathname} = useLocation();

  if (!background) background = "default";

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <TSuiBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{overflowX: "hidden"}}
    >
      {children}
    </TSuiBox>
  );
}

export default PageLayout;
