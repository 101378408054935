import {IPackage} from "../../interfaces/packages";
import OutlinedPricingCard from "../OutlinedPricingCard";
import {postData} from "../../api/api";
import {ModalMaker} from "../ModalMaker/ModalMaker";
import {TSuiTypography} from "../TSuiTypography";
import CircularProgress from "@mui/material/CircularProgress";
import {TSuiBox} from "../TSuiBox";
import {useState} from "react";
import {ClientEvent} from "clientevent";

interface Props {
  packageData: IPackage[];
  userId?: string;
}

export function PackageCards(props: Props) {
  const {packageData = []} = props;
  const [processing, setProcessing] = useState(false);
  const onBuy = (pack: IPackage) => {
    const {active, ...packageData} = pack;
    if (props.userId) {
      packageData['user_id'] = props.userId;
    }
    console.log("Clicked " + packageData)
    setProcessing(true);
    postData("credits/buy", packageData)
      .then((result) => {
        console.log(result);
        document.location.href = result.data.url;
      }).catch((err) => {
      console.log(err);
      setProcessing(false);
      ClientEvent.publish("SNACKBAR", `Error: ${err.message}`);
    });
  }

  return <div style={{
    display: "flex",
    padding: 3,
    flexDirection: "row",
    overflowX: "auto",
    justifyContent: "space-around",
  }}>
    {packageData.map((pack) => {
      return <OutlinedPricingCard title={pack.display}
                                  key={pack.id}
                                  color={"dark"}
                                  description={`${pack.credits} hours`}
                                  price={{value: `$${pack.price}`}}
                                  specifications={[]}
                                  onClick={() => {
                                    onBuy(pack);
                                  }}
                                  action={{type: "external", route: "/", label: "Buy Now"}}
      />
    })}
    <ModalMaker title={""} actions={[]} open={processing}>
      <TSuiBox textAlign={"center"}>
        <TSuiTypography variant="h5" fontWeight="medium">
          Please wait while we process your request...
        </TSuiTypography>
        <CircularProgress color={"secondary"}/>
      </TSuiBox>
    </ModalMaker>
  </div>
}