/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../../../components/TSuiBox";
import {TSuiTypography} from "../../../components/TSuiTypography";

// Soft UI Dashboard PRO React example components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "../PageLayout";

// Authentication layout components
import Footer from "../Footer";

// Soft UI Dashboard PRO React page layout routes
// import pageRoutes from "page.routes";

function BasicLayout({title, description, image, children, gridOverrides = {}}) {
  return (
    <PageLayout>
      {/*<DefaultNavbar*/}
      {/*  routes={pageRoutes}*/}
      {/*  action={{*/}
      {/*    type: "external",*/}
      {/*    route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",*/}
      {/*    label: "buy now",*/}
      {/*  }}*/}
      {/*  transparent*/}
      {/*  light*/}
      {/*/>*/}
      <TSuiBox
        width="calc(100% - 2rem)"
        minHeight="50vh"
        borderRadius="lg"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{textAlign: "center"}}>
          <Grid item xs={10} lg={4}>
            <TSuiBox mt={6} mb={1}>
              <TSuiTypography variant="h1" color="white" fontWeight="bold">
                {title}
              </TSuiTypography>
            </TSuiBox>
            <TSuiBox mb={2}>
              <TSuiTypography variant="body2" color="white" fontWeight="regular">
                {description}
              </TSuiTypography>
            </TSuiBox>
          </Grid>
        </Grid>
      </TSuiBox>
      <TSuiBox mt={{xs: -26, lg: -24}} px={1} width="calc(100% - 2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={gridOverrides && gridOverrides.xs || 11} sm={gridOverrides && gridOverrides.sm || 9}
                md={gridOverrides && gridOverrides.md || 5} lg={gridOverrides && gridOverrides.lg || 4}
                xl={gridOverrides && gridOverrides.xl || 3}>
            {children}
          </Grid>
        </Grid>
      </TSuiBox>
      <Footer/>
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
