// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import {useEffect, useState} from "react";
import {getData, postData} from "../../api/api";
import {FormField} from "../../components/FormField";
import {TSuiButton} from "../../components/TSuiButton";
import {ClientEvent} from "clientevent";
import {IPackage} from "../../interfaces/packages";
import * as React from "react";

interface Props extends RouteComponentProps {
  package_id?: string
}

export function PackageAddEdit(props: Props) {
  const {package_id} = props;
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState<Partial<IPackage> | undefined>();

  const refreshData = async () => {
    if (package_id) {
      const data = await getData(`settings/packages/${package_id}`).then((response) => (response.data || {})).catch(() => ({}));
      setPackageData(data)
    }
  }

  const userValHandler = (key: keyof IPackage, val: any) => {
    setPackageData(prev => ({...prev, [key]: val}));
  };

  useEffect(() => {
    refreshData();
  }, [package_id]);

  let onSubmit = () => {
    if (!packageData?.display) {
      return ClientEvent.publish("SNACKBAR", "Cannot Save. No Name defined.");
    }
    postData(`settings/packages/${package_id || ""}`, packageData).then(async (result) => {
      if (result.status === 200) {
        await navigate(`/settings/packages`)
      } else {
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err.result.statusText)
    })
  };

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={1} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            {(package_id && packageData) ?
              <>Editing Package {packageData.display}</>
              :
              <>Add New Package</>
            }
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox p={0}>
          <TSuiBox component="form" role="form">
            <TSuiBox p={3} mb={2}>
              <FormField placeholder="Display Name"
                         label={"Display Name"}
                         type="text"
                         value={packageData?.display || ""}
                         onChange={(evt) => userValHandler("display", evt.target.value)}/>

              <FormField placeholder="0.00"
                         label={"Price"}
                         type="number"
                         step={0.01}
                         value={packageData?.price || ""}
                         onChange={(evt) => userValHandler("price", evt.target.value ? Number(evt.target.value).toFixed(2) : undefined)}/>

              <FormField placeholder="0"
                         label={"Credits"}
                         type="number"
                         step={1}
                         value={packageData?.credits || ""}
                         onChange={(evt) => userValHandler("credits", evt.target.value ? Number(evt.target.value).toFixed(0) : undefined)}/>

              <FormField placeholder="Active"
                         label={"Active"}
                         type="select"
                         mb={2}
                         value={{
                           value: packageData?.active || 0,
                           label: packageData?.active ? "Yes" : "No"
                         }}
                         onChange={(evt: any) => {
                           userValHandler("active", evt.value)
                         }}
                         options={["Yes", "No"].map((entry) => ({value: entry === "Yes" ? 1 : 0, label: entry}))}
                         size="small"
              />

              <TSuiBox p={0} mb={0} textAlign="right" padding={0}>
                <TSuiButton iconOnly={false}
                            variant="gradient"
                            color="info"
                            title={"Edit Name"}
                            onClick={onSubmit}
                >
                  {(package_id && packageData) ?
                    <>Edit</>
                    :
                    <>Add</>
                  }
                </TSuiButton>
                <TSuiButton iconOnly={false}
                            variant="gradient"
                            color="dark"
                            onClick={() => navigate(`/settings/packages`)}
                >
                  Cancel
                </TSuiButton>
              </TSuiBox>

            </TSuiBox>

          </TSuiBox>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
