// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import DataTable from "../../components/DataTable";
import {useEffect, useState} from "react";
import {deleteData, getData} from "../../api/api";
import {TSuiButton} from "../../components/TSuiButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {ClientEvent} from "clientevent";
import {DialogContentType} from "../../components/Dialog/DialogControl";

interface Props extends RouteComponentProps {
  office_id?: string;
}

interface Office {
  id: string;
  display: string;
}

interface Room {
  id: string;
  office_id: string;
  display: string;
}

export function Rooms(props: Props) {
  const {office_id} = props;

  const navigate = useNavigate();
  const [office, setOffice] = useState<{ office: Office, rooms: Room[] }>({
    office: {id: props.office_id, display: "Loading..."},
    rooms: []
  });

  const refreshTables = async () => {
    const data = await getData(`offices/${office_id}/rooms`).then((response) => (response.data || {
      office: {},
      rooms: []
    })).catch(() => ({office: {}, rooms: []}));
    setOffice(data)
  }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove Space",
      message: "Are you sure you want to remove this Space?",
      buttons: ["Yes", "No"],
      onClick: (value2) => {
        if (value2 === "Yes") {
          deleteData(`offices/${office_id}/rooms/${value}`)
            .then(async (response) => {
              await refreshTables();
            })
            .catch((err) => {
              ClientEvent.publish("SNACKBAR", err.message);
            });
        }
      }
    } as DialogContentType)
  }

  useEffect(() => {
    if (props.office_id) {
      refreshTables();
    }
  }, [props.office_id]);

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0} display={"flex"} justifyContent={"space-between"}>
          <TSuiButton iconOnly={false}
                      variant="gradient"
                      color="dark"
                      onClick={() => navigate(`/settings/office`)}
          >
            Back
          </TSuiButton>
          <TSuiTypography variant="h5" fontWeight="medium">
            <>{office?.office?.display || ""} Spaces</>
          </TSuiTypography>
          <TSuiButton iconOnly={false}
                      variant="gradient"
                      color="info"
                      onClick={() => navigate(`/settings/office/${office_id}/spaces/add`)}
          >
            Add
          </TSuiButton>
        </TSuiBox>
        <TSuiBox p={0} mb={0} textAlign="right" padding={0}>

        </TSuiBox>
        <TSuiBox p={0}>
          <DataTable<Room> table={{
            columns: [{
              Header: "Space Name",
              accessor: "display",
              width: "20%"
            }, {
              Header: "Actions",
              accessor: "id",
              width: "20%",
              align: "right",
              disableSortBy: true,
              Cell: ({cell: {value}}) => (
                <>
                  <TSuiButton iconOnly={true}
                              variant="gradient"
                              color="info"
                              title={"Edit Name"}
                              onClick={() => navigate(`/settings/office/${office_id}/spaces/${value}`)}>
                    <EditIcon/>
                  </TSuiButton>
                  <TSuiButton iconOnly={true}
                              variant="gradient"
                              color="error"
                              title={"Remove"}
                              onClick={() => removeConfirm(value)}>
                    <DeleteIcon/>
                  </TSuiButton>
                </>
              )
            }],
            rows: office.rooms
          }}/>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
