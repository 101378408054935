// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import DataTable from "../../components/DataTable";
import {useEffect, useState} from "react";
import {deleteData, getData} from "../../api/api";
import {ClientEvent} from "clientevent";
import {DialogContentType} from "../../components/Dialog/DialogControl";
import {IPackage} from "../../interfaces/packages";
import {formatCurrency} from "../../shared/util";
import {TSuiButton} from "../../components/TSuiButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props extends RouteComponentProps {

}

export function Packages(props: Props) {
  const navigate = useNavigate();
  const [packages, setPackages] = useState<IPackage[]>([]);

  const refreshTables = async () => {
    const data = await getData("settings/packages").then((response) => (response.data || {packages: []})).catch(() => ({users: []}));
    setPackages(data.packages)
  }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove Package",
      message: "Are you sure you want to remove this Package?",
      buttons: ["Yes", "No"],
      onClick: (value2) => {
        if (value2 === "Yes") {
          deleteData(`settings/packages/${value}`)
            .then(async (response) => {
              await refreshTables();
            })
            .catch((err) => {
              ClientEvent.publish("SNACKBAR", err.message);
            });
        }
      }
    } as DialogContentType)
  }

  useEffect(() => {
    refreshTables();
  }, []);

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0} display={"flex"} justifyContent={"space-between"}>
          {/*<TSuiTypography variant="h5" fontWeight="medium">*/}
          {/*</TSuiTypography>*/}
          <TSuiTypography variant="h5" fontWeight="medium">
            Packages
          </TSuiTypography>
          <TSuiButton iconOnly={false}
                      variant="gradient"
                      color="info"
                      title={"Add Package"}
                      onClick={() => navigate(`/settings/packages/add`)}
          >
            Add
          </TSuiButton>
        </TSuiBox>
        <TSuiBox p={0}>
          <DataTable<IPackage>
            isSorted={false}
            canSearch={true}
            table={{
              columns: [{
                Header: "Display",
                accessor: "display",
                width: "20%"
              }, {
                Header: "Credits",
                accessor: "credits",
                width: "10%",
              }, {
                Header: "Price",
                accessor: "price",
                width: "10%",
                Cell: ({cell: {value}}) => (<>{formatCurrency(value)}</>)
              }, {
                Header: "Active",
                accessor: "active",
                width: "5%",
                Cell: ({cell: {value}}) => (<>{value ? "Yes" : "No"}</>)
              },
                {
                  Header: "Actions",
                  accessor: "id",
                  width: "20%",
                  align: "right",
                  disableSortBy: true,
                  Cell: ({cell: {value}}) => (
                    <>
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="info"
                                  title={"Edit Package"}
                                  onClick={() => navigate(`/settings/packages/${value}`)}>
                        <EditIcon/>
                      </TSuiButton>
                      {/*<TSuiButton iconOnly={true}*/}
                      {/*            variant="gradient"*/}
                      {/*            color="warning"*/}
                      {/*            title={"Credits"}*/}
                      {/*            onClick={() => navigate(`/settings/users/${value}/credits`)}>*/}
                      {/*  <Payment/>*/}
                      {/*</TSuiButton>*/}
                      {/*<TSuiButton iconOnly={true}*/}
                      {/*            variant="gradient"*/}
                      {/*            color="dark"*/}
                      {/*            title={"Reset Password"}*/}
                      {/*            onClick={() => resetPassword(value)}>*/}
                      {/*  <LockReset/>*/}
                      {/*</TSuiButton>*/}
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="error"
                                  title={"Remove"}
                                  onClick={() => removeConfirm(value)}>
                        <DeleteIcon/>
                      </TSuiButton>
                    </>
                  )
                }
              ],
              rows: packages
            }}/>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
