import * as React from "react";
import {useEffect, useMemo, useState} from "react";

// react-table components
import {useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../TSuiBox";
import {TSuiTypography} from "../TSuiTypography";
import {TSuiSelect} from "../TSuiSelect";
import {TSuiInput} from "../TSuiInput";
import {TSuiPagination} from "../TSuiPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

export interface TableColumn<T> {
  Header: string;
  accessor: keyof T;
  align?: "left" | "right";
  width?: string | number;
  Cell?: (cell) => JSX.Element;
  disableSortBy?: boolean
}

export interface TableProps<T> {
  columns: TableColumn<T>[],
  rows: T[]
}

interface Props<T> {
  entriesPerPage?: { defaultValue: number, entries: number[] },
  canSearch?: boolean,
  showTotalEntries?: boolean,
  table: TableProps<T>,
  pagination?: {
    variant: "contained" | "gradient",
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light",
  },
  isSorted?: boolean,
  noEndBorder?: boolean,
  defaultState?: object
  headerRight?: React.ReactNode;
}

export default function DataTable<T = any>(props: Props<T>) {
  const {
    entriesPerPage = {defaultValue: 10, entries: [5, 10, 15, 20, 25]},
    canSearch = false,
    showTotalEntries = true,
    table,
    pagination = {variant: "gradient", color: "info"},
    isSorted = true,
    headerRight,
    noEndBorder = false,
    defaultState = {},
  } = props;
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 25;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25, 50, 75, 100];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable({columns, data, initialState: {pageIndex: 0, ...defaultState}} as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: {pageIndex, pageSize, globalFilter},
  } = tableInstance as any;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 25), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({value}) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <TSuiPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </TSuiPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({target: {value}}) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({target: value}) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {

    if (column.disableSortBy) return false;

    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{boxShadow: "none"}}>
      {entriesPerPage || canSearch ? (
        <TSuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <TSuiBox display="flex" alignItems="center">
              <TSuiSelect
                defaultValue={{value: defaultValue, label: defaultValue}}
                options={entries.map((entry) => ({value: entry, label: entry}))}
                // @ts-ignore
                onChange={setEntriesPerPage}
                size="small"
              />
              <TSuiTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </TSuiTypography>
            </TSuiBox>
          )}
          {canSearch && (
            <TSuiBox width="12rem" ml="auto">
              <TSuiInput
                placeholder="Search..."
                value={search}
                onChange={({currentTarget}) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </TSuiBox>
          )}
          {headerRight || null}
        </TSuiBox>
      ) : null}
      <Table {...getTableProps()}>
        <TSuiBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </TSuiBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TSuiBox
        display="flex"
        flexDirection={{xs: "column", sm: "row"}}
        justifyContent="space-between"
        alignItems={{xs: "flex-start", sm: "center"}}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <TSuiBox mb={{xs: 3, sm: 0}}>
            <TSuiTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </TSuiTypography>
          </TSuiBox>
        )}
        {pageOptions.length > 1 && (
          <TSuiPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            <>
              {canPreviousPage && (
                <TSuiPagination item onClick={() => previousPage()}>
                  <Icon sx={{fontWeight: "bold"}}>chevron_left</Icon>
                </TSuiPagination>
              )}
              {renderPagination.length > 6 ? (
                <TSuiBox width="5rem" mx={1}>
                  <TSuiInput
                    inputProps={{type: "number", min: 1, max: customizedPageOptions.length}}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination as any, handleInputPaginationValue)}
                  />
                </TSuiBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <TSuiPagination item onClick={() => nextPage()}>
                  <Icon sx={{fontWeight: "bold"}}>chevron_right</Icon>
                </TSuiPagination>
              )}
            </>
          </TSuiPagination>
        )}
      </TSuiBox>
    </TableContainer>
  );
}


