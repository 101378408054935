import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"

dayjs.extend(utc);

export function zLen(str: string, len: number) {
  while (str.length < len) {
    str = "0" + str;
  }
  return str;
}

export function formatDate(date: Date) {
  return `${String(date.getUTCFullYear())}-${zLen(String(date.getUTCMonth() + 1), 2)}-${zLen(String(date.getUTCDate()), 2)}`;
}

export function formatLocalDate(date: Date) {
  return `${String(date.getFullYear())}-${zLen(String(date.getMonth() + 1), 2)}-${zLen(String(date.getDate()), 2)}`;
}

export function formatTime(date: Date) {
  return `${zLen(String(date.getUTCHours()), 2)}:${zLen(String(date.getUTCMinutes()), 2)}:${zLen(String(date.getUTCSeconds()), 2)}`;
}

export function formatLocalTime(date: Date) {
  return `${zLen(String(date.getHours()), 2)}:${zLen(String(date.getMinutes()), 2)}:${zLen(String(date.getSeconds()), 2)}`;
}

export function formatDateTime(date: Date) {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function formatLocalDateTime(date: Date) {
  return `${formatLocalDate(date)} ${formatLocalTime(date)}`;
}

export function formatTimeFromEpoch(dateNumber: number, offsetSeconds = 0) {
  return new Date((Number(dateNumber) - Number(offsetSeconds)) * 1000);
}

export function makeDate(dateStr: string): Date {
  return new Date(dateStr);
}

export function forceUTCTimeZone(date: Date, inverse: boolean = false) {
  const localOffsetMinutes = (date).getTimezoneOffset();
  date.setMinutes(date.getMinutes() + (!inverse ? localOffsetMinutes : (localOffsetMinutes * -1)));
  return date;
}

export function stdTimezoneOffset() {
  const now = new Date();
  const jan = new Date(now.getFullYear(), 0, 1);
  const jul = new Date(now.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function isInDaylightSavingTime(date: Date) {
  return date.getTimezoneOffset() < stdTimezoneOffset();
}

export function parseDate(dateStr: string | number) {
  return dayjs.utc(dateStr);
}

export function startOfDay(dateStr: string | number) {
  return parseDate(dateStr).startOf("day");
}

export function endOfDay(dateStr: string | number) {
  return parseDate(dateStr).endOf("day");
}

export function epochNow() {
  return dayjs.utc(formatLocalDateTime(new Date()));
}

export function dateStringToEpochMs(dateStr: string) {
  return parseDate(dateStr).unix() * 1000;
}

export function epochMsToDateString(epochms: number) {
  return parseDate(epochms).format("YYYY-MM-DD");
}

export function epochMsToDateTimeObject(epochms: number) {
  const day = parseDate(epochms);
  return {
    date: day.format("YYYY-MM-DD"),
    time: day.format("h:mm a")
  }
}

export function epochToMs(epoch: number) {
  return epoch * 1000;
}

export function epochFromMs(epochMs: number) {
  return Math.floor(epochMs / 1000);
}

export function epochMsToDateTimeDiff(start: number, end: number) {
  const day = Math.abs(parseDate(start).diff(end, "minute"));
  return day;
}

export function minToDurationWords(min: number) {
  const hours = min / 60;
  if (min < 60) {
    return `${min} mins`;
  }
  if (hours === 1) {
    return `1 hr`
  } else {
    return `${hours} hrs`
  }
}

export function calcDuration(startDate?: string, startTime?: string, endTime?: string) {
  if (!!startDate && !!startTime && !!endTime) {
    return epochMsToDateTimeDiff(parseDate(`${startDate} ${startTime}:00`).unix() * 1000, parseDate(`${startDate} ${endTime}:00`).unix() * 1000)
  }
  return 0;
}

export function makeEpoch(startDate: string, startTime: string) {
  return parseDate(`${startDate} ${startTime}:00`).unix();
}

export function milToHuman(milTime: string) {
  let am = true;
  return milTime.split(":").map((calc: string, index) => {
    if (index === 0) {
      const num = Number(calc)
      if (num > 11) am = false;
      if (num > 12) {
        return `${num - 12}`;
      }
      return `${num}`;
    } else {
      return calc;
    }
  }).join(":").concat(` ${am ? "am" : "pm"}`);
}