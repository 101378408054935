// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import DataTable from "../../components/DataTable";
import {useEffect, useState} from "react";
import {deleteData, getData} from "../../api/api";
import {ClientEvent} from "clientevent";
import {DialogContentType} from "../../components/Dialog/DialogControl";
import {CreditType} from "../../interfaces/credits";
import {TSuiButton} from "../../components/TSuiButton";
import {CreditEditor} from "../../modals/CreditEditor";

interface Props extends RouteComponentProps {
  user_id?: string;
}

export function UserCredits(props: Props) {
  const {user_id} = props;
  const navigate = useNavigate();
  const [credits, setCredits] = useState<CreditType[]>([]);
  const [editCredit, setEditCredit] = useState<string | undefined>();
  const [addCredit, setAddCredit] = useState<boolean>(false);

  const refreshTables = async () => {
    const data = await getData(`users/${user_id}/credits`).then((response) => (response.data || [])).catch(() => ([]));
    setCredits(data)
  }

  // const resetPassword = (value: string) => {
  //   ClientEvent.emit("DIALOG", {
  //     title: "Reset Password",
  //     type: "prompt",
  //     buttons: ['Change', 'Cancel'],
  //     onClick: (btnValue: string | null, inputValue: string) => {
  //       console.log(btnValue, inputValue)
  //       if (btnValue === "Change" && inputValue.length > 0) {
  //         postData(`users/${value || ""}/password`, {password: inputValue})
  //           .then(async (result) => {
  //             if (result.status === 200) {
  //               await navigate(`/settings/users`)
  //               ClientEvent.publish("SNACKBAR", "Password changed");
  //             } else {
  //               ClientEvent.publish("SNACKBAR", `Password NOT changed: ${result.statusText}`);
  //               console.log(result.statusText)
  //             }
  //           }).catch((err) => {
  //           ClientEvent.publish("SNACKBAR", `Password NOT changed: ${err.response.statusText}`);
  //           console.log(err.result.statusText)
  //         })
  //       } else {
  //         ClientEvent.publish("SNACKBAR", "Password NOT changed");
  //       }
  //     }
  //   } as DialogControlPrompt)
  // }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove User",
      message: "Are you sure you want to remove this Users Credit?",
      buttons: ["Yes", "No"],
      onClick: async (value2) => {
        if (value2 === "Yes") {
          try {
            await deleteData(`users/${user_id}/credits/${value}`);
            await refreshTables();
          } catch (err) {
            ClientEvent.publish("SNACKBAR", err.message);
          }
        }
      }
    } as DialogContentType)
  }

  useEffect(() => {
    refreshTables();
  }, []);

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0} display={"flex"} justifyContent={"space-between"}>
          {/*<TSuiTypography variant="h5" fontWeight="medium">*/}
          {/*</TSuiTypography>*/}
          <TSuiTypography variant="h5" fontWeight="medium">
            User Credits
          </TSuiTypography>
          {/*<TSuiButton iconOnly={false}*/}
          {/*            variant="gradient"*/}
          {/*            color="info"*/}
          {/*            title={"Edit Name"}*/}
          {/*            onClick={() => navigate(`/settings/office/add`)}*/}
          {/*>*/}
          {/*  Add*/}
          {/*</TSuiButton>*/}
        </TSuiBox>
        <TSuiBox p={0}>
          <DataTable<CreditType>
            headerRight={<TSuiButton iconOnly={false}
                                     variant="gradient"
                                     color="info"
                                     title={"Add Credit"}
                                     onClick={() => setAddCredit(true)}
            >
              Add
            </TSuiButton>}
            isSorted={true}
            defaultState={{sortBy: [{id: "activate", desc: false}]}}
            table={{
              columns: [{
                Header: "Activated",
                accessor: "activate",
                width: "15%",
                Cell: ({cell: {value}}) => (<>{new Date(value * 1000).toISOString().split("T")[0]}</>)
              }, {
                Header: "Expires",
                accessor: "expires",
                width: "15%",
                Cell: ({cell: {value}}) => (<>{new Date(value * 1000).toISOString().split("T")[0]}</>)
              }, {
                Header: "Credits",
                accessor: "credits",
                width: "5%",
                Cell: ({cell: {value}}) => (<>{value}</>)
              }, {
                Header: "Bonus",
                accessor: "bonus",
                width: "5%",
                Cell: ({cell: {value}}) => (<>{value ? "Yes" : "No"}</>)
              },
                {
                  Header: "Actions",
                  accessor: "id",
                  width: "20%",
                  align: "right",
                  disableSortBy: true,
                  Cell: ({cell: {value}}) => (
                    <>
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="info"
                                  title={"Edit User"}
                                  onClick={() => setEditCredit(value)}>
                        <EditIcon/>
                      </TSuiButton>
                      {/*<TSuiButton iconOnly={true}*/}
                      {/*            variant="gradient"*/}
                      {/*            color="warning"*/}
                      {/*            title={"Credits"}*/}
                      {/*            onClick={() => navigate(`/settings/users/${value}/credits`)}>*/}
                      {/*  <Payment/>*/}
                      {/*</TSuiButton>*/}
                      {/*<TSuiButton iconOnly={true}*/}
                      {/*            variant="gradient"*/}
                      {/*            color="dark"*/}
                      {/*            title={"Reset Password"}*/}
                      {/*            onClick={() => resetPassword(value)}>*/}
                      {/*  <LockReset/>*/}
                      {/*</TSuiButton>*/}
                      <TSuiButton iconOnly={true}
                                  variant="gradient"
                                  color="error"
                                  title={"Remove"}
                                  onClick={() => removeConfirm(value)}>
                        <DeleteIcon/>
                      </TSuiButton>
                    </>
                  )
                }
              ],
              rows: credits
            }}/>
        </TSuiBox>
      </Card>
      {editCredit &&
        <CreditEditor userId={user_id} creditId={editCredit} onClose={() => setEditCredit(undefined)} onSave={() => {
          refreshTables()
          setEditCredit(undefined);
        }}/>}
      {addCredit &&
        <CreditEditor userId={user_id} creditId={undefined} onClose={() => setAddCredit(false)} onSave={() => {
          refreshTables()
          setAddCredit(false);
        }}/>}
    </DashboardLayout>
  );
}
