/*
 * name validation
 * accepted: letters & spaces, minimum 3 chars, maximum 15 chars
 */
export const name: RegExp = /[a-zA-Z\ \'\-]{1,30}/;

/*
 * email validation
 */
export const email: RegExp = /^[^\s@]+@[^\s@]+\.([^\s@]{2,})+$/;

/*
 * password validation, should contain:
 * (?=.*\d){1,}: at least one digit
 * (?=.*[a-z]){1,}: at least one lower case
 * (?=.*[A-Z]){1,}: at least one uppercase case
 * (?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]){0,}: at least zero special characters
 * [0-9a-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]{6,}: at least 6 from the mentioned characters
 */
export const password: RegExp =
  /^(?=.*\d){1,}(?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]){0,}[0-9a-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e]{6,}$/;
