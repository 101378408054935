// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import DataTable from "../../components/DataTable";
import {useEffect, useState} from "react";
import {deleteData, getData, postData} from "../../api/api";
import {TSuiButton} from "../../components/TSuiButton";
import {ClientEvent} from "clientevent";
import {DialogContentType} from "../../components/Dialog/DialogControl";
import {Refresh} from "@mui/icons-material";

interface Props extends RouteComponentProps {

}

export function Offices(props: Props) {
  const navigate = useNavigate();
  const [offices, setOffices] = useState<{ id: string, display: string, numRooms: number }[]>([]);

  const refreshTables = async () => {
    const data = await getData("offices").then((response) => (response.data || {offices: []})).catch(() => ({offices: []}));
    setOffices(data.offices)
  }

  const removeConfirm = (value: string) => {
    ClientEvent.emit("DIALOG", {
      type: "confirm",
      title: "Remove Office",
      message: "Are you sure you want to remove this Office?",
      buttons: ["Yes", "No"],
      onClick: (value2) => {
        if (value2 === "Yes") {
          deleteData(`offices/${value}`)
            .then(async (response) => {
              await refreshTables();
            })
            .catch((err) => {
              ClientEvent.publish("SNACKBAR", err.message);
            });
        }
      }
    } as DialogContentType)
  }

  const refreshDisplay = async (officeId: string) => {
    await postData(`offices/${officeId}/refreshDisplay`, {}).then(() => {
      ClientEvent.publish("SNACKBAR", "Refresh Sent");
    });
  }

  useEffect(() => {
    refreshTables();
  }, []);

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0} display={"flex"} justifyContent={"space-between"}>
          <TSuiTypography variant="h5" fontWeight="medium">
          </TSuiTypography>
          <TSuiTypography variant="h5" fontWeight="medium">
            Offices
          </TSuiTypography>
          <TSuiButton iconOnly={false}
                      variant="gradient"
                      color="info"
                      title={"Edit Name"}
                      onClick={() => navigate(`/settings/office/add`)}
          >
            Add
          </TSuiButton>
        </TSuiBox>
        <TSuiBox p={0}>
          <DataTable<{ id: string, display: string, numRooms: number }>
            // isSorted={true}
            table={{
              columns: [{
                Header: "Office Name",
                accessor: "display",
                width: "50%"
              }, {
                Header: "Spaces",
                accessor: "numRooms",
                width: "30%",
              }, {
                Header: "Actions",
                accessor: "id",
                width: "20%",
                align: "right",
                disableSortBy: true,
                Cell: ({cell: {value}}) => (
                  <>
                    <TSuiButton iconOnly={true}
                                variant="gradient"
                                color="secondary"
                                title={"Spaces"}
                                onClick={() => navigate(`/settings/office/${value}/spaces`)}>
                      <MeetingRoomIcon/>
                    </TSuiButton>
                    <TSuiButton iconOnly={true}
                                variant="gradient"
                                color="warning"
                                title={"Refresh Airport Display"}
                                onClick={() => refreshDisplay(value)}>
                      <Refresh/>
                    </TSuiButton>
                    <TSuiButton iconOnly={true}
                                variant="gradient"
                                color="info"
                                title={"Edit Name"}
                                onClick={() => navigate(`/settings/office/${value}`)}>
                      <EditIcon/>
                    </TSuiButton>
                    <TSuiButton iconOnly={true}
                                variant="gradient"
                                color="error"
                                title={"Remove"}
                                onClick={() => removeConfirm(value)}>
                      <DeleteIcon/>
                    </TSuiButton>
                  </>
                )
              }],
              rows: offices
            }}/>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
