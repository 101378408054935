export const webDataStore = {
  data: {
    login: "",
    password: "",
    token: "",
    pushToken: "",
    updateAvailable: false,
    updateReady: false,
  } as WebDataStoreOptions,
  setDataStore(props: Partial<WebDataStoreOptions>) {
    this.data = {...this.data, ...props}
  }
}

interface WebDataStoreOptions {
  login: string;
  password: string;
  token: string;
  pushToken: string;
  updateAvailable: boolean;
  updateReady: boolean;
}