import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useState} from "react";

interface Props {
  title: string | JSX.Element;
  children: string | JSX.Element | JSX.Element[];

  actions: string | JSX.Element | JSX.Element[];
  open?: boolean;
  onClose?: () => void;
}

export function ModalMaker(props: Props) {
  // const [open, setOpen] = useState<boolean>(props.defaultOpen || false);

  return <Dialog open={props.open || false}
                 onClose={() => props.onClose && props.onClose()}
                 fullWidth
  >
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      {props.open && props.children}
    </DialogContent>
    <DialogActions>
      {props.open && props.actions}
    </DialogActions>
  </Dialog>
}