import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {TSuiButton} from "../components/TSuiButton";
import {TSuiInput} from "../components/TSuiInput";
import {TSuiBox} from "../components/TSuiBox";
import {TSuiTypography} from "../components/TSuiTypography";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {getData} from "../api/api";
import {ClientEvent} from "clientevent";
import {epochFromMs, epochToMs, parseDate} from "../shared/dateutils";

interface Props {
  reservationId: string;
  newReservationData?: { start: number, end: number; office_id?: string, user_id?: string };
  showUser?: boolean;
  onSave?: (reservation: any) => void;
  onRemove?: () => void;
  onClose?: () => void;
  onCheckout?: () => void;
  onCheckin?: () => void;
}

export function ReservationEditor(props: Props) {
  const {reservationId, onSave, onClose, onRemove, onCheckout, onCheckin, showUser, newReservationData} = props;
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [options, setOptions] = useState<{ offices: any[], users: any[] }>({offices: [], users: []})
  const [reservation, setReservation] = useState<any | undefined>()

  useEffect(() => {
    if (reservationId) {
      getData(`reservations/${showUser ? "all/" : ""}${reservationId}`)
        .then((results) => {
          const {offices = [], users = [], ...reservation} = results.data
          setOptions({offices, users});
          setReservation({
            ...reservation.reservation,
            start: epochToMs(reservation.reservation.start),
            end: epochToMs(reservation.reservation.end),
          })

          setShowDialog(true);
        })
        .catch((err) => {
          ClientEvent.publish("SNACKBAR", `Error: ${err.message}`)
          props.onClose && props.onClose();
        })
    }
    if (newReservationData) {
      getData(`reservations/${showUser ? "all/" : ""}add`)
        .then((results) => {
          const {offices = [], users = []} = results.data
          setOptions({offices, users});
          setReservation({
            start: epochToMs(newReservationData.start),
            end: epochToMs(newReservationData.end),
            office_id: newReservationData.office_id,
            user_id: newReservationData.user_id
          })
          setShowDialog(true);
        })
        .catch((err) => {
          console.log(err)
          ClientEvent.publish("SNACKBAR", `Error: ${err.message}`)
          props.onClose && props.onClose();
        })
    }
    return () => {
      setShowDialog(false);
    }
  }, [reservationId, newReservationData]);

  if ((!props.reservationId && !props.newReservationData) || !showDialog) return null;

  const getOffice = (officeId: string) => options?.offices.find((elm) => elm.id === officeId) || {id: "", display: ""};
  const getUser = (userId: string) => options?.users.find((elm) => elm.id === userId) || {
    id: "",
    first_name: "",
    last_name: ""
  };

  return <Dialog open={true}
                 onClose={() => onClose && onClose()}
                 fullWidth
  >
    <DialogTitle>{reservationId ? "Manage" : "Add"} Reservation</DialogTitle>
    <DialogContent>
      <TSuiBox p={0}>
        <TSuiBox p={0} display={"flex"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Date
          </TSuiTypography>
          <TSuiInput readOnly={true} type="date" placeholder="Date"
                     value={parseDate(reservation.start).format("YYYY-MM-DD")}/>
        </TSuiBox>

        <TSuiBox p={0} display={"flex"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Start Time
          </TSuiTypography>
          <TSuiInput readOnly={true} type="time" placeholder="Time"
                     value={parseDate(reservation.start).format("HH:mm:ss")}/>
        </TSuiBox>

        <TSuiBox p={0} display={"flex"} justifyContent={"space-evenly"} alignItems={"center"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            End Time
          </TSuiTypography>
          <TSuiInput readOnly={true} type="time" placeholder="Time"
                     value={parseDate(reservation.end).format("HH:mm:ss")}/>
        </TSuiBox>

        <TSuiBox p={0} display={"flex"}>
          <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
            Office
          </TSuiTypography>
          <Autocomplete
            style={{width: "100%"}}
            getOptionLabel={(option: any) =>
              `${option.display}`
            }
            filterOptions={(x) => x}
            options={options.offices}
            autoComplete
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => {
              return option.id === value?.id
            }}
            value={getOffice(reservation.office_id)}
            onChange={(event, newValue) => {
              if (!!newValue) {
                setReservation(prev => ({...prev, office_id: newValue.id}))
              } else {
                setReservation(prev => ({...prev, office_id: ""}))
              }
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth/>
            )}
          />
        </TSuiBox>

        {props.showUser && (

          <TSuiBox p={0} display={"flex"}>
            <TSuiTypography variant={"body2"} whiteSpace={"nowrap"} p={1} width={200}>
              User
            </TSuiTypography>
            <Autocomplete
              style={{width: "100%"}}
              getOptionLabel={(option: any) =>
                `${option.first_name} ${option.last_name}`.trim()
              }
              filterOptions={(x) => x}
              options={options.users}
              isOptionEqualToValue={(option, value) => {
                return option.id === value?.id
              }}
              autoComplete
              value={getUser(reservation.user_id)}
              filterSelectedOptions
              onChange={(event, newValue) => {
                if (!!newValue) {
                  // console.log(newValue)
                  setReservation(prev => ({...prev, user_id: newValue.id}))
                } else {
                  setReservation(prev => ({...prev, user_id: ""}))
                }
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth/>
              )}
            />
          </TSuiBox>
        )}

      </TSuiBox>
    </DialogContent>
    <DialogActions>
      {onCheckout && reservationId && (
        <TSuiButton iconOnly={false}
                    variant="gradient"
                    color="secondary"
                    title={"Save then Close dialog"}
                    onClick={() => {
                      onCheckout();
                    }}
        >
          Check Out
        </TSuiButton>
      )}
      {onCheckin && reservationId && (
        <TSuiButton iconOnly={false}
                    variant="gradient"
                    color="secondary"
                    title={"Save then Close dialog"}
                    onClick={() => {
                      onCheckin();
                    }}
        >
          Check In
        </TSuiButton>
      )}
      <TSuiButton iconOnly={false}
                  variant="gradient"
                  color="info"
                  title={"Save then Close dialog"}
                  onClick={() => {
                    onSave && onSave({
                      ...reservation,
                      start: epochFromMs(reservation.start),
                      end: epochFromMs(reservation.end)
                    });
                  }}
      >
        {reservationId ? "Save" : "Add"}
      </TSuiButton>
      {onRemove && reservationId && (

        <TSuiButton iconOnly={false}
                    variant="gradient"
                    color="error"
                    title={"Remove/Cancel Reservation"}
                    onClick={() => onRemove()}
        >
          Remove
        </TSuiButton>
      )}

      <TSuiButton iconOnly={false}
                  variant="gradient"
                  color="dark"
                  title={"Close Dialog Without Saving"}
                  onClick={() => onClose && onClose()}
      >
        Cancel
      </TSuiButton>
    </DialogActions>
  </Dialog>
}