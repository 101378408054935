export function parseCookie(str: string) {
  return str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      if (v.length > 1) {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      }
      return acc;
    }, {} as { [key: string]: string });
}

export function plural(count: number, singular: string, plural: string) {
  if (count === 1) {
    return `${singular}`;
  }
  return `${plural}`;
}

export function formatCurrency(amt: null | number) {
  if (!amt) return "";
  return `$${amt.toFixed(2)}`;
}