import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { TabPanelContextProvider, useTabContext } from "./TabPanelContext";

interface Props {
  defaultTab?: string;
  tabs: string[];
  children: JSX.Element | JSX.Element[];
}

function _TabsWithPanels(props: Props) {
  const { state: localState, setState: updateLocalState } = useTabContext();
  return (
    <>
      <Tabs
        value={localState.tab}
        onChange={(_, tab) => updateLocalState({ tab })}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
      >
        {props.tabs.map((tab) => (
          <Tab key={tab} label={tab} value={tab} />
        ))}
      </Tabs>
      {props.children}
    </>
  );
}

export const TabsWithPanels = (props: Props) => {
  return (
    <TabPanelContextProvider value={{ tab: props.defaultTab || false }}>
      <_TabsWithPanels {...props} />
    </TabPanelContextProvider>
  );
};
