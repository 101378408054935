import {TSuiBox} from "../TSuiBox";
import {TSuiTypography} from "../TSuiTypography";
import {TSuiInput, TSuiInputProps} from "../TSuiInput";
import {TSuiSelect} from "../TSuiSelect";

interface Props extends TSuiInputProps {
  label?: string;
  mb?: number;
  type?: string;
}

export function FormField(props: Props) {
  const {label = "", ...rest} = props;
  return (
    <TSuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%" mb={props.mb}>
      <TSuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <TSuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </TSuiTypography>
      </TSuiBox>
      {props.type === "select" ?
        // @ts-ignore
        <TSuiSelect {...rest} />
        :
        <TSuiInput {...rest} />
      }
    </TSuiBox>
  );
}
