import { useTabContext } from "./TabPanelContext";

interface Props {
  value: string;
  children: JSX.Element | JSX.Element[];
}

export function TabPanel(props: Props) {
  const {
    state: { tab },
  } = useTabContext();

  if (tab !== props.value) return null;
  return <>{props.children}</>;
}
