import {Icon, InputBase, InputBaseProps, useTheme} from "@mui/material";
import {useMemo} from "react";
import {useSoftUIController} from "../context";

export interface TSuiInputProps extends Omit<InputBaseProps, "size"> {
  size?: "small" | "medium" | "large";
  icon?: { component: JSX.Element | false, direction: "none" | "left" | "right" }
  error?: boolean;
  success?: boolean;
  disabled?: boolean;

  [key: string]: any;
}

export function TSuiInput(props: TSuiInputProps) {
  const {
    size: sizeOrig,
    icon: iconOrig,
    error: errorOrig,
    success: successOrig,
    disabled: disabledOrig,
    ...rest
  } = props;

  const size = typeof sizeOrig === "undefined" ? "medium" : sizeOrig;
  const icon = typeof iconOrig === "undefined" ? {component: false, direction: "none"} : iconOrig;
  const error = typeof errorOrig === "undefined" ? false : errorOrig;
  const success = typeof successOrig === "undefined" ? false : successOrig;
  const disabled = typeof disabledOrig === "undefined" ? false : disabledOrig;

  const theme = useTheme();
  const [controller] = useSoftUIController();
  const {direction} = controller;
  const iconDirection = icon.direction;

  const inputStyle = useMemo(() => {
    // @ts-ignore
    const {palette, boxShadows, functions, typography, borders} = theme;
    // const { size, error, success, iconDirection, direction, disabled } = props;

    // @ts-ignore
    const {inputColors, grey, white, transparent} = palette;
    const {inputBoxShadow} = boxShadows;
    const {pxToRem, boxShadow} = functions;
    // @ts-ignore
    const {size: fontSize} = typography;
    const {borderRadius} = borders;

    // styles for the input with size="small"
    const smallStyles = () => ({
      fontSize: fontSize.xs,
      padding: `${pxToRem(4)} ${pxToRem(12)}`,
    });

    // styles for the input with size="large"
    const largeStyles = () => ({
      padding: pxToRem(12),
    });

    // styles for the focused state of the input
    let focusedBorderColorValue = inputColors.borderColor.focus;

    if (error) {
      focusedBorderColorValue = inputColors.error;
    } else if (success) {
      focusedBorderColorValue = inputColors.success;
    }

    let focusedPaddingLeftValue;

    if (direction === "rtl" && iconDirection === "left") {
      focusedPaddingLeftValue = pxToRem(12);
    } else if (direction === "rtl" && iconDirection === "right") {
      focusedPaddingLeftValue = pxToRem(12);
    } else if (direction === "ltr" && iconDirection === "right") {
      focusedPaddingLeftValue = pxToRem(12);
    } else if (direction === "ltr" && iconDirection === "left") {
      focusedPaddingLeftValue = pxToRem(12);
    }

    let focusedPaddingRightValue;

    if (direction === "rtl" && iconDirection === "left") {
      focusedPaddingRightValue = pxToRem(12);
    } else if (direction === "rtl" && iconDirection === "right") {
      focusedPaddingRightValue = pxToRem(12);
    } else if (direction === "ltr" && iconDirection === "right") {
      focusedPaddingRightValue = pxToRem(12);
    } else if (direction === "ltr" && iconDirection === "left") {
      focusedPaddingRightValue = pxToRem(12);
    }

    let focusedBoxShadowValue = boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1);

    if (error) {
      focusedBoxShadowValue = inputBoxShadow.error;
    } else if (success) {
      focusedBoxShadowValue = inputBoxShadow.success;
    }

    // styles for the input with error={true}
    const errorStyles = () => ({
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3E%3C/svg%3E\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${pxToRem(12)} center`,
      backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
      borderColor: inputColors.error,
    });

    // styles for the input with success={true}
    const successStyles = () => ({
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%2366d432' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${pxToRem(12)} center`,
      backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
      borderColor: inputColors.success,
    });

    // styles for the input containing an icon
    const withIconStyles = () => {
      let withIconBorderRadiusValue = `0 ${borderRadius.md} ${borderRadius.md} 0`;

      if (direction === "rtl" && iconDirection === "left") {
        withIconBorderRadiusValue = `0 ${borderRadius.md} ${borderRadius.md} 0`;
      } else if (direction === "rtl" && iconDirection === "right") {
        withIconBorderRadiusValue = `${borderRadius.md} 0 0 ${borderRadius.md}`;
      } else if (direction === "ltr" && iconDirection === "right") {
        withIconBorderRadiusValue = `${borderRadius.md} 0 0 ${borderRadius.md}`;
      }

      let withIconPaddingLeftValue;
      if (direction === "rtl" && iconDirection === "left") {
        withIconPaddingLeftValue = 0;
      } else if (direction === "rtl" && iconDirection === "right") {
        withIconPaddingLeftValue = pxToRem(12);
      } else if (direction === "ltr" && iconDirection === "right") {
        withIconPaddingLeftValue = pxToRem(12);
      } else if (direction === "ltr" && iconDirection === "left") {
        withIconPaddingLeftValue = 0;
      }

      let withIconPaddingRightValue;

      if (direction === "rtl" && iconDirection === "left") {
        withIconPaddingRightValue = pxToRem(12);
      } else if (direction === "rtl" && iconDirection === "right") {
        withIconPaddingRightValue = 0;
      } else if (direction === "ltr" && iconDirection === "right") {
        withIconPaddingRightValue = 0;
      } else if (direction === "ltr" && iconDirection === "left") {
        withIconPaddingRightValue = pxToRem(12);
      }

      return {
        borderColor: transparent.main,
        borderRadius: withIconBorderRadiusValue,
        paddingLeft: withIconPaddingLeftValue,
        paddingRight: withIconPaddingRightValue,
      };
    };

    return {
      backgroundColor: disabled ? `${grey[200]} !important` : white.main,
      pointerEvents: (disabled ? "none" : "auto") as any,
      ...(size === "small" && smallStyles()),
      ...(size === "large" && largeStyles()),
      ...(error && errorStyles()),
      ...(success && successStyles()),
      ...((iconDirection === "left" || iconDirection === "right") && withIconStyles()),

      "&.MuiFocused": {
        borderColor: focusedBorderColorValue,
        paddingLeft: focusedPaddingLeftValue,
        paddingRight: focusedPaddingRightValue,
        boxShadow: focusedBoxShadowValue,
        outline: 0,
      },

      "&.MuiInputBaseMultiline": {
        padding: `${pxToRem(10)} ${pxToRem(12)}`,
      },
    };
  }, [direction, disabled, error, iconDirection, size, success, theme]);

  return useMemo(() => {
    if (typeof (icon.component) !== "boolean" && icon.component && icon.direction === "left") {
      // @ts-ignore
      return <TSuiInputWithIconRoot theme={theme} error={error} success={success} disabled={disabled}>
        <TSuiInputIconBoxRoot theme={theme} size={size}>
          <TSuiInputIconRoot theme={theme} fontSize="small" size={size}>
            {icon.component}
          </TSuiInputIconRoot>
        </TSuiInputIconBoxRoot>
        <InputBase style={{...inputStyle}}
                   {...rest}/>
      </TSuiInputWithIconRoot>
    } else if (typeof (icon.component) !== "boolean" && icon.component && icon.direction === "right") {
      // @ts-ignore
      return <TSuiInputWithIconRoot theme={theme} error={error} success={success} disabled={disabled}>
        <InputBase style={{...inputStyle}}
                   {...rest}/>
        <TSuiInputIconBoxRoot theme={theme} size={size}>
          <TSuiInputIconRoot theme={theme} fontSize="small" size={size}>
            {icon.component}
          </TSuiInputIconRoot>
        </TSuiInputIconBoxRoot>
      </TSuiInputWithIconRoot>
    } else {
      return <InputBase style={{...inputStyle}}
                        {...rest}/>
    }
  }, [direction, iconDirection, rest])
}

interface IconProps {
  size: "small" | "medium" | "large";
  theme: any;
  children: JSX.Element | string

  [key: string]: any;
}

function TSuiInputIconRoot(props: IconProps) {
  const {size, theme: {typography}, ...rest} = props;

  const style = useMemo(() => {
    const {fontWeightBold, size: fontSize} = typography;

    return {
      fontWeight: fontWeightBold,
      fontSize: size === "small" && `${fontSize.md} !important`,
    };
  }, [size, typography]);

  return <Icon style={{...style}} {...rest}/>
}

interface IconBoxProps {
  size: "small" | "medium" | "large";
  theme: any;
  children: JSX.Element;
}

function TSuiInputIconBoxRoot(props: IconBoxProps) {
  const {size, theme: {palette, functions}, children} = props;
  const style = useMemo(() => {
      const {dark} = palette;
      const {pxToRem} = functions;

      return {
        lineHeight: 0,
        padding: size === "small" ? `${pxToRem(4)} ${pxToRem(10)}` : `${pxToRem(8)} ${pxToRem(10)}`,
        width: pxToRem(39),
        height: "100%",
        color: dark.main,
      }
    }, [size, palette, functions]
  );

  return <div style={{...style}}>{children}</div>
}

interface InputWithIconRoot {
  theme: any;
  error: boolean;
  success: boolean;
  disabled: boolean;
  children: JSX.Element;
}

function TSuiInputWithIconRoot(props: InputWithIconRoot) {
  const {error, success, disabled, theme: {palette, functions, borders}, children} = props;

  const style = useMemo(() => {
      const {inputColors, grey, white} = palette;
      const {pxToRem} = functions;
      const {borderRadius, borderWidth} = borders;

      // border color value
      let borderColorValue = inputColors.borderColor.main;

      if (error) {
        borderColorValue = inputColors.error;
      } else if (success) {
        borderColorValue = inputColors.success;
      }

      return {
        display: "flex",
        alignItems: "center",
        backgroundColor: disabled ? grey[200] : white.main,
        border: `${borderWidth[1]} solid`,
        borderRadius: borderRadius.md,
        borderColor: borderColorValue,

        "& .MuiInputBaseInput": {
          height: pxToRem(20),
        },
      };
    }, [error, success, disabled, palette, functions, borders]
  );
  return <div style={{...style}}>{children}</div>
}