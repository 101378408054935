
import {Link, RouteComponentProps} from "@reach/router";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {TSuiButton} from "../../components/TSuiButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "../../layouts/comoponents/PageLayout";

// Soft UI Dashboard PRO React base styles
import typography from "../../assets/theme/base/typography";

// Authentication layout components
import Footer from "../../layouts/comoponents/Footer";

// Images
import error404 from "../../assets/images/illustrations/error-404.png";

interface Props extends RouteComponentProps {

}

export function Error404(props: Props) {
  const { d1, d3, d4, d5 } = typography;

  return (
    <PageLayout background={"white"}>
      <TSuiBox my={24} height="calc(100vh - 24rem)">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={9} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <TSuiBox
                fontSize={{ xs: d5.fontSize, sm: d4.fontSize, md: d3.fontSize, lg: d1.fontSize }}
                lineHeight={1.2}
              >
                <TSuiTypography variant="inherit" color="error" textGradient fontWeight="bold">
                  Error 404
                </TSuiTypography>
              </TSuiBox>
              <TSuiTypography variant="h2" color="dark" textGradient fontWeight="bold">
                Erm. Page not found
              </TSuiTypography>
              <TSuiBox mt={1} mb={2}>
                <TSuiTypography variant="body1" color="text">
                  We suggest you to go to the homepage while we solve this issue.
                </TSuiTypography>
              </TSuiBox>
              <TSuiBox mt={4} mb={2}>
                <TSuiButton component={Link} to="/" variant="gradient" color="dark">
                  go to homepage
                </TSuiButton>
              </TSuiBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TSuiBox component="img" src={error404} alt="error-404" width="100%" />
            </Grid>
          </Grid>
        </Grid>
      </TSuiBox>
      <Footer />
    </PageLayout>
  );
}