import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {SoftUIControllerProvider} from "./context";
import {LocationProvider} from "@reach/router";
import {GlobalStateProvider} from "./context/GlobalStateProvider";
import "./index.css";
import "./fonts/AvenirBook/avenir-book.ttf";
import "./fonts/AvenirBook/avenir-book.woff";
import "./fonts/AvenirBook/avenir-book.woff2";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <GlobalStateProvider>
    <LocationProvider>
      <SoftUIControllerProvider>
        <App/>
      </SoftUIControllerProvider>
    </LocationProvider>
  </GlobalStateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
