// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../TSuiBox";
import {TSuiTypography} from "../TSuiTypography";
import {TSuiButton} from "../TSuiButton";

// Soft UI Dashboard PRO React base styles
import borders from "../../assets/theme/base/borders";

function OutlinedPricingCard({
                               color = "dark",
                               title,
                               description,
                               price,
                               specifications,
                               action,
                               onClick
                             }: OutlinedPricingCardProps) {

  const {borderWidth, borderColor} = borders;

  const renderSpecifications = specifications.map(({label, includes}, key) => (
      <TSuiBox
        key={label}
        display="flex"
        alignItems="center"
        pb={specifications.length - 1 !== key ? 2 : 0}
      >
        <TSuiTypography variant="body1"
                        color={includes ? "success" : "error"}
                        sx={
                          {
                            lineHeight: 0
                          }
                        }>
          <Icon sx={
            {
              fontWeight: "bold"
            }
          }>
            {
              includes ? "done" : "close"
            }
          </Icon>
        </TSuiTypography>
        <TSuiBox
          pl={2}
          lineHeight={1}>
          <TSuiTypography variant="button"
                          color="text"
                          fontWeight="regular">
            {label}
          </TSuiTypography>
        </TSuiBox>
      </TSuiBox>
    ))
  ;

  return (
    <TSuiBox height="100%"
             borderRadius="xl"
             border={`${borderWidth[1]} solid ${borderColor}`
             }>
      <TSuiBox pt={3}
               pb={0.5}
               px={3}
               lineHeight={1}
               textAlign="center">
        <TSuiTypography variant="h5"
                        color={color}>
          {title}
        </TSuiTypography>
        <TSuiBox
          mb={2}
          mt={0.5}>
          <TSuiTypography variant="button"
                          color="text"
                          fontWeight="regular">
            {description}
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox
          mt={2}
          mb={1}>
          <TSuiTypography variant="h3"
                          color={color}
                          fontWeight="bold">
            <>{price.value} &nbsp;</>
            {price.type && (
              <TSuiTypography variant="button"
                              color="text"
                              fontWeight="regular">
                <>/ {price.type}</>
              </TSuiTypography>
            )}
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox
          mt={3}
          mb={1}>
          {onClick ? (
            <TSuiButton
              onClick={() => onClick()}
              variant="gradient"
              color={color}
              size="small"
              fullWidth
            >
              {action.label}
            </TSuiButton>
          ) : action.type === "internal" ? (
              <TSuiButton
                component={Link}
                to={action.route}
                variant="gradient"
                color={color}
                size="small"
                fullWidth
              >
                {action.label}
              </TSuiButton>
            ) :
            (
              <TSuiButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={color}
                size="small"
                fullWidth
              >
                {action.label}
              </TSuiButton>
            )
          }
        </TSuiBox>
      </TSuiBox>
      <Divider/>
      <TSuiBox pt={1}
               pb={3}
               px={3}>
        {renderSpecifications}
      </TSuiBox>
    </TSuiBox>
  )
    ;
}

interface OutlinedPricingCardProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  title: string;
  description: string;
  price: {
    value: string;
    type?: string;
  };
  specifications: { label: string, includes: boolean }[];
  action: {
    type: "external" | "internal";
    route: string;
    label: string;
  };
  onClick?: () => void;
}

export default OutlinedPricingCard;
