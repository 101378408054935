/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState} from "react";

// prop-types is a library for typechecking of props

// @mui material components
import Fade from "@mui/material/Fade";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../TSuiBox";

// Custom styles for the SuiAlert
import TSuiAlertRoot from "./TSuiAlertRoot";
import TSuiAlertCloseIcon from "./TSuiAlertCloseIcon";
import {TSuiAlertProps} from "./types";

function TSuiAlert({color = "info", dismissible = false, children, onClick, ...rest}: TSuiAlertProps) {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => setAlertStatus("fadeOut");

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      {/*// @ts-ignore */}
      <TSuiAlertRoot {...rest} ownerState={{color} as any}>
        <TSuiBox display="flex" alignItems="center" color="white" onClick={onClick}
                 sx={{cursor: onClick ? "pointer" : "unset"}}>
          {children}
        </TSuiBox>
        {dismissible ? (
          <TSuiAlertCloseIcon onClick={mount ? handleAlertStatus : null}>&times;</TSuiAlertCloseIcon>
        ) : null}
      </TSuiAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// // Setting default values for the props of SuiAlert
// TSuiAlert.defaultProps = {
//   color: "info",
//   dismissible: false,
// };
//
// // Typechecking props of the SuiAlert
// TSuiAlert.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//   ]),
//   dismissible: PropTypes.bool,
//   children: PropTypes.node.isRequired,
// };

export default TSuiAlert;
