import {createContext, useContext, useMemo} from "react";

import {TSuiBox} from "./TSuiBox";
import {TSuiButton, TSuiButtonProps} from "./TSuiButton";
import {useTheme} from "@mui/material";

// The Pagination main context
const Context = createContext({
  variant: "gradient",
  color: "info",
  size: "medium",
});

interface Props extends Omit<TSuiButtonProps, "children"> {
  item?: boolean,
  variant?: "gradient" | "contained",
  color?: "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark",
  size?: "small" | "medium" | "large",
  active?: boolean,
  children: string | JSX.Element,

}

export function TSuiPagination(props: Props) {
  const {
    item = false,
    variant = "gradient",
    color = "info",
    size = "medium",
    active = false,
    children,
    ...rest
  } = props;
  const theme = useTheme();
  const usedContext = useContext(Context);

  const context = item ? usedContext : null;
  const paginationSize = context ? context.size : null;

  const buttonStyle = useMemo(() => {
    // @ts-ignore
    const {borders, functions, typography, palette} = theme;
    // const { variant, paginationSize, active } = ownerState;

    const {borderColor} = borders;
    const {pxToRem} = functions;
    // @ts-ignore
    const {fontWeightRegular, size: fontSize} = typography;
    // @ts-ignore
    const {light} = palette;

    // width, height, minWidth and minHeight values
    let sizeValue = pxToRem(36);

    if (paginationSize === "small") {
      sizeValue = pxToRem(30);
    } else if (paginationSize === "large") {
      sizeValue = pxToRem(46);
    }

    return {
      borderColor,
      margin: `0 ${pxToRem(2)}`,
      pointerEvents: active ? "none" : "auto",
      fontWeight: fontWeightRegular,
      fontSize: fontSize.sm,
      width: sizeValue,
      minWidth: sizeValue,
      height: sizeValue,
      minHeight: sizeValue,

      "&:hover, &:focus, &:active": {
        transform: "none",
        boxShadow: "none !important", //(variant !== "gradient" || variant !== "contained") && "none !important",
        opacity: "1 !important",
      },

      "&:hover": {
        backgroundColor: light.main,
        borderColor,
      },
    };
  }, [paginationSize, active, variant]);

  return (
    <Context.Provider value={{variant, color, size}}>
      {item ? (
        <TSuiButton
          {...rest}
          // @ts-ignore
          variant={active ? context.variant : "outlined"}
          // @ts-ignore
          color={active ? context.color : "secondary"}
          iconOnly
          circular
          // @ts-ignore
          style={{...buttonStyle}}
        >
          {children}
        </TSuiButton>
      ) : (
        <TSuiBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{listStyle: "none"}}
        >
          {children}
        </TSuiBox>
      )}
    </Context.Provider>
  );
}