import {useEffect, useState} from "react";

// react-router-dom components
import {Link, RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import {getData} from "../../api/api";
import {useGlobalState} from "../../context/GlobalStateProvider";
import BasicLayout from "../../layouts/comoponents/BasicLayout";
import curved9 from "../../assets/images/first-slide.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import {CheckCircleOutline} from "@mui/icons-material";

interface Props extends RouteComponentProps {
  reference_id?: string;
}

export function CreditPurchaseWaiting(props: Props) {
  const {state: {configData, balance}} = useGlobalState();
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const [completed, setCompleted] = useState<boolean>(false);
  const [displaySignInButton, setDisplaySignInButton] = useState<boolean>(false);

  const refreshList = async () => {
    const data = await getData(`credits/purchase-check/${props.reference_id || "invalid"}`)
      .then((response) => (response.data.result))
      .catch(() => "ERROR");
    if (data === "WAITING") {
      setTimeout(() => {
        setCounter(counter + 1);
      }, 3000);
    } else {
      if (data === "COMPLETED") {
        setCompleted(true);
      } else {
        document.location.href = "/";
      }
    }
  }

  useEffect(() => {
    refreshList();
  }, [counter])

  useEffect(() => {
    if (completed) {
      if (configData) {
        setTimeout(() => {
          document.location.href = "/";
        }, 1500);
      } else {
        setDisplaySignInButton(true);
      }
    }
  }, [completed])


  return (
    <BasicLayout image={curved9}>
      <Card>
        <TSuiBox p={3} mb={0} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            {completed ? "Payment Complete!" : "Processing Payment..."}
          </TSuiTypography>
          <>
            {!completed ?
              <CircularProgress color={"secondary"}/>
              :
              <CheckCircleOutline fontSize={"large"} color={"success"}/>
            }
          </>
          {displaySignInButton &&
            <TSuiBox>
              <TSuiTypography variant="button" color="text" fontWeight="regular">
                <>Verify your email and then&nbsp;</>
                <TSuiTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </TSuiTypography>
              </TSuiTypography>
            </TSuiBox>
          }
        </TSuiBox>
      </Card>
    </BasicLayout>
  );
}
