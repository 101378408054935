// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import {useEffect, useState} from "react";
import {getData, postData} from "../../api/api";
import {FormField} from "../../components/FormField";
import {TSuiButton} from "../../components/TSuiButton";
import {ClientEvent} from "clientevent";

interface Props extends RouteComponentProps {
  office_id?: string
  room_id?: string
}

export function RoomAddEdit(props: Props) {
  const {office_id = "", room_id} = props;
  const navigate = useNavigate();
  const [officeData, setOfficeData] = useState<{ office: { id?: string, display: string }, room?: { id?: string, display: string } } | undefined>();

  const refreshData = async () => {
    if (office_id && room_id) {
      const data = await getData(`offices/${office_id}/rooms/${room_id}`).then((response) => (response.data || undefined)).catch(() => undefined);
      setOfficeData(data)
    } else if (office_id && !room_id) {
      const data = await getData(`offices/${office_id}/rooms/${room_id || ""}`).then((response) => ({
        ...response.data,
        rooms: undefined,
        room: undefined
      } || undefined)).catch(() => undefined);
      setOfficeData({...data})
    }

  }

  const userValHandler = (key: "display", val: string) => {
    setOfficeData(prev => {
      return {
        ...prev,
        room: {
          ...prev.room || {},
          [key]: val
        }
      }
    });
  };

  useEffect(() => {
    refreshData();
  }, [office_id, room_id]);

  let onSubmit = () => {
    if (!officeData?.room?.display) {
      return ClientEvent.publish("SNACKBAR", "Cannot Save. No Name defined.");
    }
    postData(`offices/${office_id || ""}/rooms/${room_id || ""}`, officeData.room || {}).then(async (result) => {
      if (result.status === 200) {
        await navigate(`/settings/office/${office_id}/spaces`)
      } else {
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err.result.statusText)
    })
  };

  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={1} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            {(office_id && room_id) ?
              <>Editing {officeData?.room?.display || "Space"}</>
              :
              <>Add New Space in {officeData?.office?.display || "Office"}</>
            }
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox p={0}>
          <TSuiBox component="form" role="form">
            <TSuiBox p={3} mb={2}>
              <FormField placeholder="Display Name"
                         label={"Display Name"}
                         type="text"
                         value={officeData?.room?.display || ""}
                         onChange={(evt) => userValHandler("display", evt.target.value)}/>

              <TSuiBox p={0} mb={0} textAlign="right" padding={0}>
                <TSuiButton iconOnly={false}
                            variant="gradient"
                            color="info"
                            title={"Edit Name"}
                            onClick={onSubmit}
                >
                  {(office_id && officeData) ?
                    <>Edit</>
                    :
                    <>Add</>
                  }
                </TSuiButton>
                <TSuiButton iconOnly={false}
                            variant="gradient"
                            color="dark"
                            onClick={() => navigate(`/settings/office/${office_id}/spaces`)}
                >
                  Cancel
                </TSuiButton>
              </TSuiBox>

            </TSuiBox>

          </TSuiBox>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
