/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import {TSuiBox} from "../../../components/TSuiBox";
import {TSuiTypography} from "../../../components/TSuiTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "../PageLayout";

// Soft UI Dashboard PRO React page layout routes
// import pageRoutes from "page.routes";

// Images
import pattern from "../../../assets/images/shapes/pattern-lines.svg";

function IllustrationLayout({
                              color,
                              header,
                              title,
                              description,
                              illustration,
                              children,
                              xs = 11,
                              sm = 8, md = 6, lg = 4, xl = 3,
                              bgImage,
                              bgColor = "white"
                            }) {
  return (
    <PageLayout background={bgColor}>
      <Grid container>
        <Grid item xs={xs || 11} sm={sm || 8} md={md || 6} lg={lg || 4} xl={xl || 3} sx={{mx: "auto"}}>
          <TSuiBox display="flex" flexDirection="column" justifyContent="start" height="100vh" bgColor={bgColor}>
            <TSuiBox pt={3} px={3}>
              {!header ? (
                <>
                  <TSuiBox mb={1}>
                    <TSuiTypography variant="h4" fontWeight="bold">
                      {title}
                    </TSuiTypography>
                  </TSuiBox>
                  <TSuiTypography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </TSuiTypography>
                </>
              ) : (
                header
              )}
            </TSuiBox>
            <TSuiBox p={0} bgColor={bgColor}>{children}</TSuiBox>
          </TSuiBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TSuiBox
            display={{xs: "none", lg: "flex"}}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            position="relative"
            borderRadius="lg"
            textAlign="center"
            bgColor={"#979899"}
            opacity={0.85}
            variant="gradient"
            m={2}
            px={13}
            sx={{overflow: "hidden"}}
          >
            <TSuiBox
              component="img"
              src={bgImage}
              alt="pattern-lines"
              // width="120rem"
              // position="absolute"
              height={"100%"}
              width={"auto"}
              topl={0}
              left={0}
              // opacity={0.4}
            />
            {illustration.image && (
              <TSuiBox
                component="img"
                src={illustration.image}
                alt="chat-illustration"
                width="100%"
                maxWidth="31.25rem"
              />
            )}
            {illustration.title && (
              <TSuiBox mt={6} mb={1}>
                <TSuiTypography variant="h4" color="white" fontWeight="bold">
                  {illustration.title}
                </TSuiTypography>
              </TSuiBox>
            )}
            {illustration.description && (
              <TSuiBox mb={1}>
                <TSuiTypography variant="body2" color="white">
                  {illustration.description}
                </TSuiTypography>
              </TSuiBox>
            )}
          </TSuiBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default IllustrationLayout;
