import {epochNow, epochToMs, parseDate} from "../shared/dateutils";

export interface EventUserType {
  id: string;
  title: string;
  status: string;
  start: string;
  end: string;
  backgroundColor?: string;
}

export interface EventUserTypeDTO {
  id: string;
  status: string;
  title: string;
  start: number;
  end: number;
  user_first_name: string;
  user_last_name: string;
}

function colorForStatus(event: EventUserTypeDTO): string {
  if (event.status === "checked_in") {
    if (epochToMs(event.end) < epochNow().unix() * 1000) {
      return "orange"
    } else {
      return "green"
    }
  }
  if (event.status === "checked_out") {
    return "red"
  }
  return "default"
}

export function fromEventUserTypeDTO(eventDto: EventUserTypeDTO): EventUserType {
  const {start, end, ...rest} = eventDto;
  return {
    ...rest,
    start: parseDate(epochToMs(start)).toISOString(),
    end: parseDate(epochToMs(end)).toISOString()
  }
}

export function fromEventUserTypeDTOWithColor(eventDto: EventUserTypeDTO): EventUserType {
  const {start, end, user_first_name, user_last_name, ...rest} = eventDto;
  return {
    ...rest,
    title: `${[user_first_name, user_last_name].join(" ")}`,
    start: parseDate(epochToMs(start)).toISOString(),
    end: parseDate(epochToMs(end)).toISOString(),
    backgroundColor: colorForStatus(eventDto)
  }
}