// react-router-dom components
import {RouteComponentProps, useNavigate} from "@reach/router";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
// Authentication layout components
// Images
import {TSuiBox} from "../../components/TSuiBox";
import {TSuiTypography} from "../../components/TSuiTypography";
import DashboardLayout from "../../layouts/comoponents/DashboardLayout";
import * as React from "react";
import {useEffect, useState} from "react";
import {getData, postData} from "../../api/api";
import {TSuiButton} from "../../components/TSuiButton";
import {ClientEvent} from "clientevent";
import {UserType} from "../../interfaces/users";
import {FormField} from "../../components/FormField";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {TabsWithPanels} from "../../components/TabsWithPanels/TabsWithPanels";
import {TabPanel} from "../../components/TabsWithPanels/TabPanel";

interface Props extends RouteComponentProps {
  user_id?: string;
}

type UserValHandlerParams =
  "first_name"
  | "last_name"
  | "status"
  | "admin"
  | "email"
  | "trained"
  | "bill_freq"
  | "signup_date"
  | "address"
  | "city"
  | "state"
  | "zip"
  | "temp_code"
  | "prox_card"
  | "roll_over"
  | "vip";

export function UserAddEdit(props: Props) {
  const {user_id} = props;
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserType>();
  const {state: {configData}} = useGlobalState();

  const refreshData = async () => {
    if (user_id) {
      const data = await getData(`users/${user_id}`).then((response) => (response.data)).catch(() => undefined);
      setUserData(data)
    }
  }

  const userValHandler = (key: UserValHandlerParams, val: string | number) => {
    // console.log(key, val)
    setUserData(prev => ({...prev, [key]: val}));
  };

  useEffect(() => {
    refreshData();
  }, [user_id]);

  let onSubmit = () => {
    if (!userData?.email) {
      return ClientEvent.publish("SNACKBAR", "Cannot Save. No Email defined.");
    }
    postData(`users/${user_id || ""}`, userData).then(async (result) => {
      if (result.status === 200) {
        await navigate(`/settings/users`)
      } else {
        console.log(result.statusText)
      }
    }).catch((err) => {
      console.log(err.result.statusText)
    })
  };

  let editMode = user_id && userData;
  let canEditAdmin = (userData?.id || "-1") === (configData?.id || "");
  return (
    <DashboardLayout>
      <Card>
        <TSuiBox p={3} mb={1} textAlign="center" paddingBottom={0}>
          <TSuiTypography variant="h5" fontWeight="medium">
            {editMode ?
              <>Editing {[userData.first_name, userData.last_name].join(" ").trim()}</>
              :
              <>Add New User</>
            }
          </TSuiTypography>
        </TSuiBox>
        <TSuiBox p={0}>
          <TSuiBox component="form" role="form">
            <TabsWithPanels tabs={["Personal", "Billing", "Additional"]} defaultTab={"Personal"}>
              <TabPanel value={"Personal"}>
                <TSuiBox p={3} mb={2}>
                  <FormField placeholder="Email"
                             label={"Email"}
                             type="text"
                             mb={2}
                             value={userData?.email || ""}
                             onChange={(evt) => userValHandler("email", evt.target.value)}/>

                  <FormField placeholder="First Name"
                             label={"First Name"}
                             type="text"
                             mb={2}
                             value={userData?.first_name || ""}
                             onChange={(evt) => userValHandler("first_name", evt.target.value)}/>

                  <FormField placeholder="Last Name"
                             label={"Last Name"}
                             type="text"
                             mb={2}
                             value={userData?.last_name || ""}
                             onChange={(evt) => userValHandler("last_name", evt.target.value)}/>

                  <FormField placeholder="Address"
                             label={"Address"}
                             type="text"
                             mb={0}
                             value={userData?.address || ""}
                             onChange={(evt) => userValHandler("address", evt.target.value)}/>

                  <TSuiBox display={"flex"}>
                    <TSuiBox width={"50%"}>
                      <FormField placeholder="City"
                                 label={"City"}
                                 type="text"
                                 mb={2}
                                 value={userData?.city || ""}
                                 onChange={(evt) => userValHandler("city", evt.target.value)}/>
                    </TSuiBox>
                    <TSuiBox width={"25%"}>

                      <FormField placeholder="State"
                                 label={"State"}
                                 type="text"
                                 mb={2}
                                 value={userData?.state || ""}
                                 onChange={(evt) => userValHandler("state", evt.target.value)}/>
                    </TSuiBox>
                    <TSuiBox width={"25%"}>
                      <FormField placeholder="Zip"
                                 label={"Zip"}
                                 type="text"
                                 mb={2}
                                 value={userData?.zip || ""}
                                 onChange={(evt) => userValHandler("zip", evt.target.value)}/>
                    </TSuiBox>
                  </TSuiBox>


                </TSuiBox>
              </TabPanel>
              <TabPanel value={"Billing"}>
                <TSuiBox p={3} mb={2}>
                  <FormField placeholder="Billing Frequency"
                             label={"Billing Frequency"}
                             type="select"
                             mb={2}
                             value={{
                               value: userData?.bill_freq || null,
                               label: userData?.bill_freq || "N/A"
                             }}
                             onChange={(evt: any) => {
                               userValHandler("bill_freq", evt.value)
                             }}
                             options={[null, "month", "as needed"].map((entry) => ({
                               value: entry,
                               label: entry === null ? "N/A" : entry
                             }))}
                             size="small"
                  />

                  <FormField placeholder="Signup Date"
                             label={"Signup Date"}
                             type="date"
                             mb={2}
                             value={userData?.signup_date ? userData.signup_date : ""}
                             onChange={(evt) => userValHandler("signup_date", evt.target.value === '' ? null : evt.target.value.split("T")[0])}/>

                  <FormField placeholder="Calculate Rollover"
                             label={"Calculate Rollover"}
                             type="select"
                             mb={2}
                             value={{
                               value: userData?.roll_over || 0,
                               label: userData?.roll_over ? "Yes" : "No"
                             }}
                             onChange={(evt: any) => {
                               userValHandler("roll_over", evt.value)
                             }}
                             options={["Yes", "No"].map((entry) => ({value: entry === "Yes" ? 1 : 0, label: entry}))}
                             size="small"
                  />


                </TSuiBox>
              </TabPanel>
              <TabPanel value={"Additional"}>
                <TSuiBox p={3} mb={2}>

                  <FormField placeholder="Trained"
                             label={"Trained"}
                             type="select"
                             mb={2}
                             value={{
                               value: userData?.trained || 0,
                               label: userData?.trained ? "Yes" : "No"
                             }}
                             onChange={(evt: any) => {
                               userValHandler("trained", evt.value)
                             }}
                             options={["Yes", "No"].map((entry) => ({value: entry === "Yes" ? 1 : 0, label: entry}))}
                             size="small"
                  />
                  {!canEditAdmin && (
                    <FormField placeholder="Admin"
                               label={"Admin"}
                               type="select"
                               mb={2}
                               disabled={canEditAdmin}
                               readOnly={canEditAdmin}
                               value={{
                                 value: userData?.admin || 0,
                                 label: userData?.admin ? "Yes" : "No"
                               }}
                               onChange={(evt: any) => {
                                 userValHandler("admin", evt.value)
                               }}
                               options={["Yes", "No"].map((entry) => ({value: entry === "Yes" ? 1 : 0, label: entry}))}
                               size="small"
                    />
                  )}

                  <FormField placeholder="VIP"
                             label={"VIP"}
                             type="select"
                             mb={2}
                             value={{
                               value: userData?.vip || 0,
                               label: userData?.vip ? "Yes" : "No"
                             }}
                             onChange={(evt: any) => {
                               userValHandler("vip", evt.value)
                             }}
                             options={["Yes", "No"].map((entry) => ({value: entry === "Yes" ? 1 : 0, label: entry}))}
                             size="small"
                  />

                  <FormField placeholder="Proximity Card"
                             label={"Proximity Card"}
                             type="text"
                             mb={2}
                             value={userData?.prox_card || ""}
                             onChange={(evt) => userValHandler("prox_card", evt.target.value)}/>

                </TSuiBox>
              </TabPanel>
            </TabsWithPanels>
            <TSuiBox p={0} mb={2} mr={2} textAlign="right" padding={0}>
              <TSuiButton iconOnly={false}
                          variant="gradient"
                          color="info"
                          title={"Edit Name"}
                          onClick={onSubmit}
              >
                {editMode ?
                  <>Edit</>
                  :
                  <>Add</>
                }
              </TSuiButton>
              <TSuiButton iconOnly={false}
                          variant="gradient"
                          color="dark"
                          onClick={() => navigate(`/settings/users`)}
              >
                Cancel
              </TSuiButton>
            </TSuiBox>
          </TSuiBox>
        </TSuiBox>
      </Card>
    </DashboardLayout>
  );
}
