import {useEffect} from "react";

// react-router-dom components
import {RouteComponentProps} from "@reach/router";
import {useGlobalState} from "../../context/GlobalStateProvider";
import {postData} from "../../api/api";

interface Props extends RouteComponentProps {

}

export function Logout(props: Props) {
  const {setState} = useGlobalState();

  useEffect(() => {
    postData("logout").then(() => {
      setState({userInfo: null})
    }).catch((err) => {
      console.log(err);
    });
  }, [setState]);

  return null;
}
